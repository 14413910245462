import React, {useEffect, useState} from 'react';
import css from "./Filters.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import FilterCheckbox from "./FilterCheckbox";

const FilterDropdownGroup = ({columns,data,filterState,handleChange,handleOpenState,includeAllCheckbox, openState}) => {



  const handleToggleClick = () => {
    if (openState !== data.id ) {
      handleOpenState(data.id);
    } else {
      handleOpenState(undefined);
    }
  }

  const [_openState, set_openState] = useState(false);
  useEffect(() => {
    set_openState(openState === data.id);
  },[openState])


  return <details id="filter-category" className={css.filter} open={_openState}>
    <summary
      className={classNames(css.filterButton,css.filterPrimaryButton)}
      aria-label={data.label}
      role="button"
      aria-expanded="false"
      aria-controls={`panel-${data.id}`}
      onClick={handleToggleClick}
    >
      {data.label}
    </summary>
    <div id="panel-category" className={css.filterPanel}>
      <ul role="list">

        { data?.options.map(({ label, key, subCategories, queryParamName }) => {
          return <li key={`filter-category-${key}`}>
            <details id="filter-category-care">
              <summary
                className={classNames(css.filterButton,css.filterSecondaryButton)}
                aria-label={label}
                role="button"
                aria-expanded="false"
                aria-controls="panel-category-care"
              >
                  {label}
              </summary>
              { (subCategories.length > 0 || includeAllCheckbox) && <div className={css.panel} id="panel-category-care">
                <ul role="list" className={columns ? css.columns : ""}>
                  { includeAllCheckbox  && <FilterCheckbox
                    label="ALL"
                    name={data.queryParamName}
                    value={key}
                    handleChange={ (checked) => {
                      handleChange(data.queryParamName, {
                        name : data.queryParamName,
                        key : key,
                        parentLabel : data.label,
                        label : label,
                        checked
                      })
                    }}
                    checked={
                      !!filterState?.[data.queryParamName]
                        ?.find( _filter => {
                          return _filter.key === key;
                        })
                    }
                  /> }
                  { subCategories.length > 0 && subCategories.map( (subCategory) => {
                    return <FilterCheckbox
                              key={`${key}-${subCategory.key}`}
                              label={subCategory.label}
                              name={queryParamName}
                              value={subCategory.key}
                              handleChange={ (checked) => {
                                handleChange(queryParamName, {
                                  name : queryParamName,
                                  key : subCategory.key,
                                  label : subCategory.label,
                                  parentLabel : label,
                                  checked
                                })
                              }}
                              checked={
                                !!filterState?.[queryParamName]
                                  ?.find( _filter => {
                                    return _filter.key === subCategory.key;
                                  })
                              }
                          />
                  })}
                </ul>
              </div>}
            </details>
          </li>
        })}
      </ul>
    </div>
  </details>


}

FilterDropdownGroup.propTypes = {
  classNames : PropTypes.bool,
  data : PropTypes.object.isRequired,
  handleChange : PropTypes.func.isRequired,
  openState : PropTypes.string,
  includeAllCheckbox : PropTypes.bool
}

FilterDropdownGroup.defaultProps = {
  columns : false,
  includeAllCheckbox : false,
  isOpen: null
}

export default FilterDropdownGroup;