import React, {useState, useEffect, useRef} from 'react';
import {
  Button,
  Filters,
  CurrentFilters,
  QuickFilters
} from '..';
import css from './FilterContainer.css';

import {injectIntl} from 'react-intl';
import {useHistory} from "react-router-dom";
import {filters} from "../../marketplace-custom-config";
import SortByNew from "../SortByNew/SortByNew";

const FilterContainer = ({onMapToggleClick, mapIsShown}) => {

  const history = useHistory();
  const [hasReadQueryStringOnMount, setHasReadQueryStringOnMount] = useState(false);
  const [filterState, setFilterState] = useState({});
  const [sortState, setSortState] = useState('');
  const [hideSold, setHideSold] = useState(true);
  const [page, setPage] = useState(1);
  const [keyWords, setKeyWords] = useState('');
  const [otherParams, setOtherParams] = useState({});
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  // on location uri change read query string from uri to set the state for all search params
  useEffect(() => {
    const queryParams = new URLSearchParams(history?.location?.search);

    if (!hasReadQueryStringOnMount) {
      setHasReadQueryStringOnMount(true);

      const flattenedOptions = filters?.map(({id, label, queryParamName, options}) => {
        return options.map(option => ({
          name: queryParamName,
          queryParamName: option.queryParamName || 'pub_subCategories',
          key: option.key,
          parentLabel: label,
          label: option.label,
          checked: true,
          subCategories: option.subCategories
        }))
      }).flat();

      const flattenedSubcategories = flattenedOptions?.map(({queryParamName, label, subCategories}) => {
        return subCategories?.map(subCategory => ({
          name: queryParamName,
          key: subCategory.key,
          parentLabel: label,
          label: subCategory.label,
          checked: true,
        }))
      }).flat();

      queryParams.forEach((value, key) => {
        if (key === 'sort') {
          setSortState(value);
        } else if (key === 'hideSold') {
          setHideSold(value === 'true')
        } else if (key === 'page') {
          setPage(parseInt(value, 10));
        } else if (key === 'keywords') {
          setKeyWords(value);
        } else if (key === 'mapSearch') {

        } else if (key === 'bounds') {

        } else if (
          key === 'pub_category'
          || key === 'pub_subCategories'
          || key === 'pub_ages'
          || key === 'pub_genders'
          || key === 'pub_condition'
          || key === 'pub_seasons'
          || key === 'pub_collection'
        ) {
          return !!value && value?.split(',')?.forEach(v => {
            // find the filter from the level 1 config and add to state
            const topLevelFilter = flattenedOptions.find(({key}) => v === key);

            if (!!topLevelFilter) {
              // let's not add the subcategories to the state unnecessarily.
              const {subcategories, ..._topLevelFilter} = topLevelFilter;
              addFilterToState(key, _topLevelFilter);
            } else {
              //get all the subcategories, then, and check them!
              const secondLevelFilter = flattenedSubcategories.find(({key}) => v === key);
              if (!!secondLevelFilter) {
                addFilterToState(key, secondLevelFilter)
              }
            }
          })
        } else {
          const otherParamObj = otherParams;
          otherParams[key] = value;
          setOtherParams(otherParamObj);
        }
      })
    } else {
      // do this when stuff has changed by places outside this component;
      queryParams.forEach((value, key) => {
        if (key === 'page') {
          setPage(parseInt(value, 10));
        } else if (key === 'keywords') {
          setKeyWords(value);
        } else if (key === 'sort') {
          setSortState(value);
        } else if (key === 'reset') {
          // reset other state
          setHideSold(true);
          setFilterState({});
        }
      });
    }
  }, [history?.location?.search]);

  // listener for changes to filter state and other params
  useEffect(() => {
    if (hasReadQueryStringOnMount) {
      //build and push url from state;
      const queryArray = [];

      Object.keys(filterState).map(filter => {
        queryArray.push(`${filter}=${encodeURIComponent(filterState[filter].map(({key}) => key).join(','))}`)
      })

      const newSearchQuery = `?${queryArray.join('&')}&sort=${sortState}&hideSold=${hideSold}&keywords=${encodeURIComponent(keyWords)}&page=${page}&${new URLSearchParams(otherParams).toString()}`;

      if (newSearchQuery !== history.location.search) {
        history.push(`s${newSearchQuery}`)
      }
    }
  }, [filterState, sortState, hideSold, keyWords, hasReadQueryStringOnMount]);

  // handle updating/adding a new search filter into the state of the current search
  const addFilterToState = (name, value, resetPaging = false) => {
    setFilterState((filterState) => {
      const objectToChange = {};

      if (!!filterState[name]) {
        objectToChange[name] = [...filterState[name], value];
      } else {
        objectToChange[name] = [value];
      }

      return {
        ...filterState,
        ...objectToChange
      }
    })

    if (resetPaging) {
      setPage(1)
    }
  }

  // handle removing an existing search filter from the state of the current search
  const removeFilterFromState = (name, value, resetPaging = false) => {
    setFilterState((filterState) => {
      const objectToChange = {}
      objectToChange[name] = filterState[name]?.filter(({key}) => key !== value.key) || [];

      return {
        ...filterState,
        ...objectToChange
      }
    })

    if (resetPaging) {
      setPage(1)
    }
  }

  // handler to pass down to select components to listen for changes
  const handleFilterChange = (name, value) => {
    if (value.checked) {
      addFilterToState(name, value, true);
    } else {
      removeFilterFromState(name, value, true);
    }
  }

  // handler for clear all buttons to remove all state from current search
  const handleClearAll = () => {
    setFilterState({});
    setPage(1);
  }

  // handler for the sort drop down
  const handleSortByChange = (value) => {
    setSortState(value);
    setPage(1);
  }

  // handler for the sold checkbox
  const handleSoldChange = (value) => {
    setHideSold(value);
    setPage(1);
  }

  // handler to reset the keywords on current search
  const removeKeyWord = () => {
    setKeyWords('');
    setPage(1);
  }

  // handler to toggle the filter dropdowns on the page
  const handleToggleOpenFilters = () => {
    setFilterIsOpen(!filterIsOpen);
  };

  return (
    <div className={css.root} aria-expanded={filterIsOpen}>
      <div className={css.mobileFilterButtons}>
        <Button
          className={css.mobileFilterButton}
          onClick={handleToggleOpenFilters}>
          Filters
        </Button>
        <SortByNew
          rootClassName={css.mobileSortBy}
          sortState={sortState}
          label="Sorting"
          handleSortByChange={handleSortByChange}
        />
      </div>
      <div className={css.top}>
        <Filters
          filterState={filterState}
          handleClearAll={handleClearAll}
          handleFilterChange={handleFilterChange}
          handleToggleOpenFilters={handleToggleOpenFilters}
        />
        <SortByNew
          className={css.sortByDesktop}
          sortState={sortState}
          handleSortByChange={handleSortByChange}
        />
      </div>
      <CurrentFilters
        filterState={filterState}
        removeKeyWord={removeKeyWord}
        keyWords={keyWords}
        handleClearAll={handleClearAll}
        handleFilterClick={removeFilterFromState}
      />
      <QuickFilters
        filterState={filterState}
        handleSoldChange={handleSoldChange}
        handleFilterChange={handleFilterChange}
        hideSold={hideSold}
        onMapToggleClick={onMapToggleClick}
        mapIsShown={mapIsShown}
      />
    </div>
  );
};

FilterContainer.defaultProps = {};
FilterContainer.propTypes = {};

export default injectIntl(FilterContainer);
