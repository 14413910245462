import React, { Component } from 'react';
import { object, PropTypes } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form,Button,IconSearch } from '../../components';
import {withRouter} from "react-router-dom";

import css from './TopbarSearchForm.css';

const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
    this.state = {
        keyword : ''
    }
  }

  initialKeyword() {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    return queryParams?.get('keywords') || '';
  }

  onSubmit(values) {
    /*this.props.onSubmit({
        sort : 'createdAt',
        hideSold : 'true',
        keywords: values.keywords,
        page : "1"

    });*/
      this.props.history.push(`/s?sort=relevance&keywords=${encodeURIComponent(values.keywords)}&page=1&reset=true`)
    // blur search input to hide software keyboard
    if (this?.props?.searchInput?.current) {
      this.props.searchInput.current.blur();
    }
    this.props.closeSearch();
    this.props.closeNavigation()
  }

  handleKeywordChange(keyword) {
//      this.setState({keyword});
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        initialValues={{ keywords: this.initialKeyword() }}
        render={formRenderProps => {
          const { rootClassName, className, intl, isMobile, handleSubmit } = formRenderProps;
          const classes = classNames(rootClassName, className);
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.container}>
                <Field
                name="keywords"
                render={({ input, meta }) => {
                  return (
                    <>
                      <input
                        autofocus
                        className={css.input}
                        {...input}
                        id="keyword-search"
                        type="search"
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.placeholder',
                        })}
                        autoComplete="off"
                      />
                      <Button
                        rootClassName={css.submitButton}
                        type="submit">
                          <IconSearch
                            className={css.icon}
                          />
                          <span className={css.submitButtonLabel}>
                            Search
                          </span>
                        </Button>
                    </>
                  );
                }}
              />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,

  // currentSearchParams: object.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default withRouter(TopbarSearchForm);
