import React from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  menuFirstFooterColumn,
  menuSecondFooterColumn,
  menuThirdFooterColumn,
} from '../../ducks/ghost.duck';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = null; //twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const facebookLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;


  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [instragramLink,facebookLink,twitterLink].filter(v => v != null);
};

const FooterComponent = props => {
  const {
    rootClassName,
    className,
    intl,
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.wrapper}>
        <div className={css.content}>
          <div className={css.column}>
              <h3 className={css.heading}>The Octopus Club</h3>
              <ul className={css.list}>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'how-it-works' }}>
                    How it works
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="OctoplusPage">
                    Octoplus
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'my-story' }}>
                    Our story
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'ink-blog' }}>
                    Ink Blog
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'community' }}>
                    Community
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.column}>
            <h3 className={css.heading}>Help &amp; Support</h3>
              <ul className={css.list}>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'faqs' }}>
                    FAQs
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="GhostPage" params={{ slug: 'the-octopus-club-guide' }}>
                    The Octopus Club Guide
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="ContactUsPage">
                    Contact us
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.column}>
            <h3 className={css.heading}>Legal</h3>
              <ul className={css.list}>
                <li>
                  <NamedLink name="CookiePolicyPage">
                   Cookie Policy
                   </NamedLink>
                </li>
                <li>
                  <NamedLink name="PrivacyPolicyPage">
                    Privacy Policy
                    </NamedLink>
                </li>
                <li>
                  <NamedLink name="TermsOfServicePage">
                    Terms of Service
                    </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.column}>
            <h3 className={css.heading}>Follow us</h3>
            <div className={css.socialMedia}>
              {socialMediaLinks}
            </div>
            </div>

            <div className={classNames(css.column,css.mailingList)}>
            <h3 className={css.heading}>Subscribe to our newsletter</h3>
             <p>Receive the latest news, updates, special promotions and more.</p>
             <form>
              <div className={css.mailingListFieldCombo}>
                <label htmlFor="email" className={css.mailingListFieldComboLabel}>Email address</label>
                <input className={css.mailingListEmail} name="email" type="email" placeholder="Email Address" />
                <button className={css.mailingListButton} type="submit">Subscribe</button>
              </div>
             </form>
             </div>
             </div>

             <p className={css.legal}>©2022 The Octopus Club. All Rights Reserved.</p>
        </div>
      </div>
  );
};

const mapStateToProps = state => {
  const { ghostPages } = state.ghost;

  return {
    pagesFirstfooterColumn: menuFirstFooterColumn(ghostPages),
    pagesSecondFooterColumn: menuSecondFooterColumn(ghostPages),
    pagesThirdFooterColumn: menuThirdFooterColumn(ghostPages),
  };
};

const mapDispatchToProps = dispatch => ({});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const Footer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FooterComponent);

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape,
  showLogo: bool,
};

export default injectIntl(Footer);
