export class LoggingAnalyticsHandler {
  trackPageView(url) {
  }
}

export class GoogleAnalyticsHandler {
  constructor(ga) {
    if (typeof ga !== 'function') {
      throw new Error('Variable `ga` missing for Google Analytics');
    }
    this.ga = ga;
  }
  trackPageView(url) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    this.ga('set', 'page', url);
    this.ga('send', 'pageview');
  }
}

export class FacebookPixel {
  constructor() {
    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', '336858918194775');
  }

  trackPageView(url) {
    if (url.includes('/signup')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
      window.fbq('track', 'Signup page');
    } else if (url === '/l/new') {
      // DO NOTHING, CANT WORKOUT WHY THE EXISTING ROUTING DOES THIS
    } else if (url.includes('/new/description')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
      window.fbq('track', 'Start Listing');
    } else if (url.includes('/checkout')) {
      // window.fbq('track', 'PageView');
      window.fbq('track', 'InitiateCheckout');
    } else if (url.includes('/order/')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'Purchase');
    } else if (url.includes('/l/')) {
      window.fbq('track', 'PageView');
      window.fbq('track', 'View Content');
    } else {
      window.fbq('track', 'PageView');
    }
  }
}
